<template  lang="">
    <!-- 会员提现审核 -->
    <div class="mainBox">
        <!-- 第二行 表格操作栏 -->
        <div class="selectionBar">
            <div>
				<el-input placeholder="客户名称/电话" style="width:300px" v-model.trim="select.search" :clearable="true" size="small" class="input-with-select" prefix-icon="el-icon-search" @input="curr=1;getList()"></el-input>
                <label>时间：</label>
                <el-date-picker style="width:230px" v-model="select.create_time" type="daterange" range-separator="至" start-placeholder="创建时间" end-placeholder="筛选" format="yyyy-MM-dd" value-format="yyyy-MM-dd" @change="curr=1;getList()">
                </el-date-picker>
                <el-select v-model="select.is_examine"  placeholder="状态" clearable filterable @change="curr=1;getList()">
                    <el-option
                        label="未审核"
                        value="2">
                    </el-option>
                    <el-option
                        label="已审核"
                        value="1">
                    </el-option>
                </el-select>
                
            </div>
        </div>
        <!-- 表格 -->
        <div class="table" style="height:calc( 100% - 175px )">
            <el-table ref="multipleTable" :data="list" highlight-current-row stripe :header-cell-style="{background:'#fafdff',color:'#606266',fontWeight:'normal'}" @sort-change='getList' height="calc( 100% - 41px )">
                <el-table-column prop="realname" label="客户姓名" align="center" show-overflow-tooltip></el-table-column>
                <el-table-column prop="garage_name" label="公司名称" align="center" show-overflow-tooltip></el-table-column>
                <el-table-column prop="nickname" label="昵称" align="center" show-overflow-tooltip></el-table-column>
                <el-table-column prop="mobile" label="电话" align="center" show-overflow-tooltip></el-table-column>
				<el-table-column label="头像" show-overflow-tooltip>
				    <template slot-scope="scope">
				        <el-image v-if="scope.row.headimgurl" style="width: 45px;height: 45px;border-radius: 4px;" :src="scope.row.headimgurl"  :preview-src-list="[scope.row.headimgurl]"></el-image>
				        <el-avatar v-else shape="square" :size="40" :src="require('../../assets/ava.png')"></el-avatar>
				    </template>
				</el-table-column>
                <el-table-column prop="create_time" label="申请时间" align="center" show-overflow-tooltip></el-table-column>
                <el-table-column label="状态" align="center">
                    <template slot-scope="scope">
                        <div class="">
                            <el-button plain type="success" size="small" @click="handleEdit(scope.row)">编辑</el-button>
                            <el-button plain type="success" size="small" @click="handleAudit(scope.row,1)" v-if="scope.row.is_examine==2">通过</el-button>
							<el-button size="small" type="success" v-if="scope.row.is_examine==1">已审核</el-button>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <div class="paging">
                <el-pagination @current-change="currentChange" @size-change="sizeChange" :current-page="curr" :page-size="row" background layout="total, sizes, prev, pager, next, jumper" :total="count"></el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import {auditListApi,auditMemberSaveApi ,auditEditGarageName}  from '@/api/audit.js';
export default {
    props: {
        searchValue: String
    },
    data() {
        return {
            select: {},
            list: [], //列表

            curr: 1, //页码
            row: 10, //每页条数
            pages: 1, //总页数
            count: 0, //总条数
        }
    },
    mounted: function() {
        this.getList();
    },
    methods: {
        getList: function(){
            auditListApi({
                curr:this.curr,
                row:this.row,
                ...this.select,
				is_register:1,
				// is_examine:2
            }).then(response=>{
				
				console.log("resp",response)
                this.list = response.list;
                this.curr = response.curr;
                this.pages = response.pages;
                this.count = response.count;
            })
        },
        handleEdit(row){
            console.log(row.id)
            this.$prompt('请输入公司名称', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                }).then(({ value }) => {
                    auditEditGarageName({
                        id: row.id,
                        garage_name : value
                    }).then(() => {
                        this.$message({
                            type: 'success',
                            message: "修改成功"
                        });
                        this.getList()
                    })
                    
                }).catch(() => {});
        },
        // 审核
        handleAudit(row,stateId) {
			this.$confirm('此操作将通过审核, 是否继续', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
			}).then(() => {
				this.saveAudit(row.id,stateId);
			})
        },
        saveAudit: function(id,status){
            auditMemberSaveApi({
                id:id,
                is_examine:status,
            }).then(response=>{
				console.log("热水",response)
				if(response){
					 this.$message({
						 type: response == 1 ? 'success' : 'error',
						 message: response == 1 ? '审核已通过！' : '审核未通过！'
					 })
				}
                this.getList();
            })
        },
        //翻页
        currentChange: function(curr) {
            this.curr = curr;
            this.getList();
        },
        //改变每页条数
        sizeChange: function(row) {
            this.row = row;
            this.getList();
        },
    }
}
</script>
